/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment } from "react"
import { Section } from "elements-ui"
import styled from "@emotion/styled"
import { SEO } from "@maker-ui/seo"

import { EmailIcon, PhoneIcon, LinkedInIcon } from "../assets/icons"

export default () => (
  <Fragment>
    <SEO
      title="Contact"
      description="Contact us by reaching out via the contact form on this web page or sending an email directly to alison@momentousmeetings.com"
    />
    <Section
      bg="#000"
      sx={{
        color: "#fff",
        fontFamily: "heading",
        fontSize: 18,
        p: "40px 20px 20px",
        a: { color: "primary" },
      }}
    >
      <ContactGrid>
        <div className="col" sx={{ width: ["100%", "30%"] }}>
          <strong>Business Office</strong>
          <br />
          20 Barberry Hill Road
          <br />
          PO Box 95
          <br />
          Sharon, CT 06069
          <br />
        </div>
        <div
          className="col"
          sx={{ width: ["100%", "30%"], flex: ["none", 1], mt: [30, 0] }}
        >
          <div className="contact-link">
            <PhoneIcon />
            <a href="tel:+19174711871">917-471-1871</a>
          </div>
          <div className="contact-link">
            <EmailIcon />
            <a href="mailto:alison@momentousmeetings.com">Send an Email</a>
          </div>
          <div className="contact-link">
            <LinkedInIcon />
            <a href="https://www.linkedin.com/in/alison-bluestone-b8411013">
              LinkedIn
            </a>
          </div>
        </div>
      </ContactGrid>
    </Section>
    <Section padding="60px 20px 80px">
      <h2 sx={{ fontSize: [32, 42], mb: 20 }}>Let's Get in Touch</h2>
      <form name="contact" method="POST" data-netlify="true">
        <Row>
          <p>
            <label>
              First Name*:
              <br /> <input type="text" name="first-name" required />
            </label>
          </p>
          <p>
            <label>
              Last Name*:
              <br /> <input type="text" name="last-name" required />
            </label>
          </p>
        </Row>
        <Row>
          <p>
            <label>
              Your Email*:
              <br /> <input type="email" name="email" required />
            </label>
          </p>
          <p>
            <label>
              Your Telephone:
              <br /> <input type="tel" name="phone" />
            </label>
          </p>
        </Row>
        <p>
          <label>
            Message*:
            <br />{" "}
            <textarea
              name="message"
              required
              sx={{ width: "100%", height: 200, boxSizing: "border-box" }}
            ></textarea>
          </label>
        </p>
        <p>
          <Submit sx={{ bg: "primary", fontFamily: "heading" }} type="submit">
            Send
          </Submit>
        </p>
      </form>
    </Section>
  </Fragment>
)

const Submit = styled.button`
  color: #fff;
  padding: 15px 30px;
  font-size: 20px;
  border: none;
  margin-top: 20px;
  border-radius: 3px;
`

const Row = styled.div`
  display: flex;

  p:first-of-type {
    margin-right: 30px;
  }

  p {
    flex: 1;
    display: flex;
    flex-direction: column;

    input {
      width: 100%;
      height: 50px;
      font-size: 18px;
      margin-bottom: 20px;
      box-sizing: border-box;
    }
  }

  @media screen and (max-width: 768px) {
    display: block;

    p:first-of-type {
      margin-right: 0;
    }
  }
`

const ContactGrid = styled.div`
  display: flex;
  flex-wrap: wrap;

  .contact-link {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
  }
  .col svg {
    height: 21px;
    fill: #fff;
    margin-right: 15px;
  }
`
